.modalCover {
  padding: 2em;
  box-sizing: border-box;
}

.selectBox, .input {
  width: 100%;
  margin: 0 auto !important;
  padding: 0.6em;
  cursor: pointer;
  box-shadow: 0.4px 0.4px 2px #555;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box !important;
  border-radius: 2px;
  position: relative;
}
.selectBox strong {
  font-weight: 400 !important;
}
.selectBox svg {
  width: 22px;
}
.selectingBoxCover {
  position: absolute;
  top: 44px;
  box-sizing: border-box;
  margin-left: -9px;
  z-index: 200;
  /* padding: 10px; */
  font-size: 14px;
  width: 100%;
  box-shadow: 0.4px 0.4px 2px #555;
  height: max-content !important;
  background-color: #fff;
  overflow-y: scroll;
}
.selectingBoxCover div {
  padding: 10px;
}
.selectingBoxCover div:hover{
  background-color: #f8f8f8;

}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  justify-self: flex-start;
}
.label {
  display: block;
  font-weight: bold;
  font-size: 15px;
}
.textarea {
  width: 100%;
  box-sizing: border-box;
  height: 200px;
  border-radius: 2px;
  box-shadow: 0.4px 0.4px 2px #555;
  padding: 0.6em;
  border: none;
  margin-bottom: 2em;
  outline: none;
}
.button {
  display: flex;
  margin: auto;
  padding: 0.7em 1.6em;
  background-color: #ddd;
  border: 1px solid #ccc;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  color: #333;
  border-radius: 2px;
}

.button:hover {
  background: #999;
  color: #fff;
}
.msg{
  text-align: center;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 13px;
  color: red;
  margin: 30px 0px 10px 0px;
}

.aboveMsg{
  text-align: center;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 13px;
  color: red;
  margin: 30px 0px 10px 0px;
}

.belowMsg{
  display: none;
  text-align: center;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 13px;
  color: red;
  margin: 30px 0px 10px 0px;
}

@media(max-width: 588px){
  .aboveMsg{
    display: none;
  }
  .belowMsg{
    display: block;
  }
  .form {
    grid-template-columns: 1fr;
  }
}

@media(max-width: 420px){
  .button{
      margin: 0;
  }
}