body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
  font-family: "Roboto", sans-serif;
  padding: 0;
  margin: 0;
}
.cursor-pointer {
  cursor: pointer;
}
h1,
h2,
h3,
h4,
h5,
h6,.title {
  font-family: "Encode Sans SC", sans-serif !important;
}

.no-list{
    list-style-type: none;
}


.text-bold,.card-title{
    font-weight: bold;
}


/* menu */


.menu-item{
    margin: 0 15px;
}

.title{
    font-weight: 600;
    color: #2d8259;
    margin-top: 1em;
    font-size: small;
}





/* layout  */

.d-flex{
    display: flex;
}
.flex-reverse-row{
    flex-direction: row-reverse;
}


.jc-center{
    justify-content: center;
}
.jc-space-between{
    justify-content: space-between;
}



.ai-center{
    align-items: center;
    height: 100%;
}



/* logical part */
