.navCover {
  width: 100%;
  background-color: #024731 !important;
  height: 50px;
}
.nav {
  width: 90%;
  margin: auto;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.heroCover {
  width: 100%;
  height: 190px;
  position: relative;
    box-shadow: .4px .4px 2px #555;   
  background-color: #006a4d;
}
.ownerCover {
  width: max-content;
  color: #fff;
  align-self: start;
}
.dropDown{
  width: max-content;
  height: max-content;
  box-sizing: border-box;
  z-index: 20;
  display: none !important;
  background-color: #fff;
  box-shadow: 0 0 3px #222;
  border-radius: 2px;
}
.profileImage{
  width:  60px;
  height: 60px;
  /* background-color: yellow; */
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
}

.profileImage img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profileImage svg{
  width: 50px;
  font-size: 40px;
  fill: #fff;
}

.dropDownCover{
  position: absolute;
  z-index: 20;
  top: 180px;
  right: 30px;
  display: none;
}
.dropDown ul{
  padding: 0px;
  margin: 0px;
}
  .dropDown ul li{
    list-style: none;
  }
  .dropDown ul li a{
      margin-bottom: 10px;
      display: flex;
      align-items: center;
    width: 100%;
    color: #bb5a55;
    padding: 1.1em;
    box-sizing: border-box;
  }
  .dropDown ul li svg{
    width: 16px;
    margin-right: 9px;
    fill: #bb5a55;
  }
.dropDown ul li a:hover{
  background-color: #f0f0f0;
}
.ownerCover h1 {
  margin-bottom: 2px;
  font-weight: 300;
}
.ownerCover div {
  display: flex;
  align-items: center;
}
.ownerCover span {
  font-weight: bold;
  margin-left: 4px !important;
  font-size: 17px;
}
.linkCover {
  align-self: flex-end;
  display: flex;
  margin-bottom: 25px;
}
.eachLinkCover {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #fff;
  font-size: 13px;
  border-left: 0.2px solid #fff;
  padding: 0em 2em 0 2em;
  transition: 0.1s ease-in-out;
}
.linkCover a:last-child{
  display: none;
}
.eachLinkCover:hover hr {
  visibility: visible;
}
.eachLinkCover hr {
  transition: 0.1s ease-in-out;
  margin-top: -9px;
  margin-bottom: 10px;
  visibility: hidden;
}
.eachLinkCover svg {
  width: 24px;
  margin-bottom: 10px;
  fill: #fff;
}


@media(max-width: 900px){
.linkCover a:nth-child(5){
  display: none;
}
.dropDownCover{
  display: block;
}
.linkCover a:nth-child(4){
  display: none;
}
.linkCover a:last-child{
  display: block;
}
.dropDown{
  display: block !important;
}
.dropDown ul li:nth-child(1){
  display: none;
}
.dropDown ul li:nth-child(2){
  display: none;
}
.dropDown ul li:nth-child(3){
  display: none;
}

}

@media(max-width: 750px){
  .linkCover a:nth-child(2){
    display: none;
  }
  .linkCover a:nth-child(3){
    display: none;
  }
  .linkCover a:last-child{
    display: block;
  }
.ownerCover h1 {
  font-size: 27px;  
}
.heroCover {
  height: max-content;
  padding-bottom: 20px;
}

.dropDownCover{ top: 140px;
}
.dropDown {
  top: 140px;
}
.dropDown ul li:nth-child(3){
  display: block;
}
.dropDown ul li:nth-child(2){
  display: block;
}
}

  @media(max-width: 450px){
    .linkCover a:nth-child(1){
      display: none;
    }
    .dropDown ul li:nth-child(1){
      display: block;
    }
    .linkCover a:last-child{
      display: block;
    }
    .profileImage{
      width:  40px;
      height: 40px;
      /* background-color: yellow; */
      margin-top: 10px;
      border-radius: 50%;
    }
    }